<template>
        <div></div>
</template>

<script>

import g from "../globals";

export default {
        async mounted() {
                let data = await g.postUrl("auth/loginDemo", {});
                g.setStorage("AUTH_TOKEN", "Bearer " + data.accessToken);
                g.removeStorage("state");
                location.replace("/");
        },
}

</script>
